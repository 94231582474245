import { CustomerPart, OnlinePartDetails } from "@ncs/ncs-api/portal-apps"
import { useScreenSizeMatch } from "@ncs/web-legos/util"
import { DetailsBlock } from "./DetailsBlock"
import PartDetailTabs from "./PartDetailTabs"
import { css } from "@emotion/react"
import { Box, Heading } from "@ncs/web-legos/components"

export interface PartDetailTabsProps {
	partDetails?: OnlinePartDetails
	initialPart?: CustomerPart
}

const PartDetails: React.FC<PartDetailTabsProps> = ({ partDetails, initialPart }) => {
	const brandName = partDetails?.brands?.map((brand) => brand.brandName).join(", ")
	const categories = partDetails?.categories.map((category) => category.categoryName).join(", ")
	const isMobile = useScreenSizeMatch("xs")

	if (!partDetails) {
		return null
	}

	const technicalInformationTabs = [
		{
			navLabel: "Product Details",
			component: (
				<>
					{initialPart?.description && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Description
							</Heading>
							<div css={styleDimensionValue}>{initialPart?.description}</div>
						</>
					)}

					{brandName && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Brand
							</Heading>
							<div css={styleDimensionValue}>{brandName}</div>
						</>
					)}
					{categories && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Product Type
							</Heading>
							<div css={styleDimensionValue}>{categories}</div>
						</>
					)}
				</>
			),
		},
		{
			navLabel: "Weight & Dimension",
			component: (
				<Box display="flex">
					<div
						css={css`
							width: 288px;
						`}
					>
						{partDetails.part?.height && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Height
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.height}”</div>
							</>
						)}

						{partDetails.part?.length && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Length
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.length}”</div>
							</>
						)}

						{partDetails.part?.width && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Width
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.width}’</div>
							</>
						)}
					</div>
					<div>
						{partDetails.part?.weight && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Weight
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.weight} lbs</div>
							</>
						)}
						{partDetails.part?.packageSizeInGallons && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Gallons
								</Heading>
								<div css={styleDimensionValue}>
									{partDetails.part?.packageSizeInGallons}
								</div>
							</>
						)}
					</div>
				</Box>
			),
		},
	]

	const technicalInformationBlock = [
		{
			title: "Product Details",
			content: [
				{ label: "Description", value: initialPart?.description },
				{ label: "Brand", value: brandName },
				{ label: "Product Type", value: categories },
			],
		},
		{
			title: "Weight & Dimension",
			content: [
				{
					label: "Height",
					value: partDetails.part?.height ? `${partDetails.part.height}”` : null,
				},
				{
					label: "Length",
					value: partDetails.part?.length ? `${partDetails.part.length}”` : null,
				},
				{
					label: "Width",
					value: partDetails.part?.width ? `${partDetails.part.width}’` : null,
				},
				{
					label: "Weight",
					value: partDetails.part?.weight ? `${partDetails.part.weight} lbs` : null,
				},
				{ label: "Gallons", value: partDetails.part?.packageSizeInGallons },
			],
		},
	]

	return isMobile ?
			<DetailsBlock technicalInformation={technicalInformationBlock} />
		:	<PartDetailTabs panels={technicalInformationTabs} />
}

const styleTabHeader = css`
	color: #111827;
	font-size: 1rem;
	font-weight: 700;
`

const styleDimensionValue = css`
	margin-top: 1rem;
	margin-bottom: 32px;
`

export default PartDetails
