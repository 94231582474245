export const visualSearch = () => {
	const context = getContext()
	const apiKey = import.meta.env.VITE_VISUAL_SEARCH_KEY
	context.nyrisSettings = {
		apiKey: apiKey,
		language: "en",
		ctaButtonText: "View more",
		browseGalleryButtonColor: "#0B75E1",
		primaryColor: "#0B75E1",
		cameraIconColour: "#0B75E1",
	}
	const s = document.createElement("script")
	s.src = "https://assets.nyris.io/nyris-widget/v1/widget.js"

	s.onload = () => {
		const style = document.createElement("style")
		style.textContent = `
			.nyris__main,
			.nyris__main * {
				font-family: "Atlas Grotesk";
				font-style: normal;
			}

			.nyris__icon {
				display: none;
			}

			.nyris__logo {
				background-image: url("/visual_search.svg");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				width: 110px;
				height: 110px;
				margin-top: -180px;
			}

			.nyris__logo svg,
			.nyris__logo img {
				display: none;
			}

			.nyris__hello-wrapper {
				margin-top: 120px;
				margin-bottom: -120px;
			}

			.nyris__logo::before {
				content: "ClickNPic";
				visibility: visible;
				align-self: stretch;
				color: var(--Neutrals-900, #111827);
				font-family: "Atlas Grotesk";
				font-size: 48px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				position: absolute;
				margin-top: 140px;
			}

			.nyris__logo::after {
				content: "Start your AI visual search here.";
				visibility: visible;
				align-self: stretch;
				color: var(--Neutrals-800, #1F2937);
				font-family: "Atlas Grotesk";
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				position: absolute;
				margin-top: 210px;
			}

			.nyris__product-info-title {
				min-height: fit-content;
			}

			.nyris__hello-browse {
				color: white !important;
				font-weight: 600;
			}

			.nyris__success-multiple-preview {
				background: var(--Neutrals-100, #f3f4f6);
			}

			.nyris__product-cta {
				background: #0b75e1;
				color: white;
    		border-color: "#0B75E1";
    		border-radius: 0.5rem;
			}

			.nyris__product-cta:hover {
					background: #00254a !important;
					border-color: #00254a !important;
			}

			.nyris__wait-spinner img {
				display: none;
			}

			.nyris__wait-spinner {
				background-color: white;
				width: 50px;
				height: 50px;
				border: 8px solid #e3e3e3;
				border-top: 8px solid #0B75E1;
				border-radius: 50%;
				animation: spin 1s linear infinite;
			}

			@keyframes spin {
					0% { transform: rotate(0deg); }
					100% { transform: rotate(360deg); }
			}

			@media (min-width: 600px) {
				.nyris__main {
					border: 1px solid #87CEFA;
					background: #FFFFFF;
					box-shadow: 0px 0px 6px 0px #D1D5DB;
				}

				.nyris__success-multiple-summary {
					margin-right: 20px;
				}
			}

			.nyris__success-multiple-preview-delete-collapse::before,
			.nyris__success-multiple-preview-collapse::before {
				visibility: visible;
				align-self: stretch;
				color: var(--Neutrals-800, #1F2937);
				font-family: "Atlas Grotesk";
				line-height: normal;
				position: absolute;
				margin-top: -25px;
			}

			.nyris__success-multiple-preview-delete-collapse::before {
				content: "Delete";
				margin-left: 20px;
			}

			.nyris__success-multiple-preview-collapse::before {
				content: "Refine";
				margin-right: 20px;
			}
    	`
		document.head.appendChild(style)
	}
	document.getElementsByTagName("head")[0].appendChild(s)
}

export const handleVisualSearch = () => {
	const iconElement = document.querySelector(".nyris__icon") as HTMLElement

	if (iconElement) {
		iconElement.click()
	}

	setTimeout(() => {
		const inputElement = document.querySelector(".search-input-text input") as HTMLInputElement
		if (inputElement) {
			inputElement.blur()
		}
	})
}

const getContext: any = () => window
