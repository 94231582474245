import { css } from "@emotion/react"
import React from "react"

type ContentItem = {
	label: string
	value: string | number | null | undefined
}

type TechnicalInformation = {
	title: string
	content: ContentItem[]
}

type TechnicalInformationProps = {
	technicalInformation: TechnicalInformation[]
}

export const DetailsBlock: React.FC<TechnicalInformationProps> = ({ technicalInformation }) => {
	return (
		<>
			{technicalInformation.map((block, index) => (
				<div key={index} css={container}>
					<div css={titleContainer}>
						<h3 css={title}>{block.title}</h3>
					</div>

					{block.title === "Weight & Dimension" ?
						<div css={weightDimensions}>
							{block.content
								.filter((item) => item.value != null)
								.map((item, id) => (
									<div key={id} css={columns}>
										<p css={label}>{item.label}</p>
										<p css={value}>{item.value}</p>
									</div>
								))}
						</div>
					:	<ul>
							{block.content
								.filter((item) => item.value != null)
								.map((item, id) => (
									<li key={id}>
										<p css={label}>{item.label}</p>
										<p css={value}>{item.value}</p>
									</li>
								))}
						</ul>
					}
				</div>
			))}
		</>
	)
}

const weightDimensions = css`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
`

const columns = css`
	width: calc(50% - 0.5rem);
	display: flex;
	flex-direction: column;
`

const container = css`
	display: flex;
	flex-direction: column;
`

const titleContainer = css`
	position: relative;
	margin: 3rem 0;

	&::before {
		content: "";
		display: block;
		border-bottom: 1px solid #e5e6e6;
		width: 100%;
		position: absolute;
		bottom: 1px;
		left: 0;
		z-index: 0;
	}
`

const title = css`
	padding-bottom: 14px;
	border-bottom: 4px solid #0b75e1;
	margin: 0 auto;
	width: 60%;
	text-align: center;
	position: relative;
	z-index: 1;
	font-weight: 700;
`

const label = css`
	color: #111827;
	font-family: "Atlas Grotesk";
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 1rem;
`

const value = css`
	color: #374151;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 2rem;
`
