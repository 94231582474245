import { css } from "@emotion/react"
import { Tabs } from "@ncs/web-legos"
import { CustomerPart, OnlinePartDetails } from "@ncs/ncs-api/portal-apps"
import { ReactElement, useState } from "react"

export interface PartDetailTabsProps {
	partDetails?: OnlinePartDetails
	initialPart?: CustomerPart
	panels?: {
		navLabel: string
		component: ReactElement
	}[]
}

const PartDetailTabs: React.FC<PartDetailTabsProps> = ({ panels }) => {
	const [tab, setTab] = useState("Product Details")

	return (
		<Tabs
			tabsBarCss={tabBar}
			tabBarCss={tabBar}
			currentTab={tab}
			onChange={setTab}
			panels={panels ?? []}
		/>
	)
}

const tabBar = css`
	font-size: 1.25rem;
	font-weight: 700;
	text-align: center;

	.MuiTabs-indicator {
		background-color: #0b75e1;
		border-radius: 100px;
		margin: 0 1rem;
	}
`

export default PartDetailTabs
