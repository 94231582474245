import { FC, useEffect, useRef } from "react"

import { CustomerSite, useSites, useAuth, useUserProfile } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { Paragraph } from "../typography"

export interface SearchableSiteSelectorEcommProps
	extends ExtendableSearchableSelectProps<CustomerSite> {
	value: CustomerSite | null
	onChange: (newValue: CustomerSite | null) => void
	initialId?: string | null
}

export const SearchableSiteSelectorEcomm: FC<SearchableSiteSelectorEcommProps> = ({
	value,
	onChange,
	initialId,
	...rest
}) => {
	const [sites, sitesAreLoading] = useSites()
	const auth = useAuth()
	const [profile] = useUserProfile(auth.user?.id)
	const isGuest = profile?.isGuest
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (!!initialId && !hasSetInitial.current && !!sites) {
			const option = sites.find((site) => site.id === initialId)

			if (option) {
				onChange(option)
				hasSetInitial.current = true
			}
		}
	}, [initialId, sites, onChange])

	const getAddressString = (option: CustomerSite) => {
		const pieces = []

		if (option.address2) pieces.push(option.address2)
		if (option.city) pieces.push(option.city)
		if (option.state) pieces.push(option.state)
		if (option.postalCode) pieces.push(option.postalCode)

		return pieces.join(", ")
	}

	return (
		<SearchableSelect
			isLoading={sitesAreLoading}
			label={isGuest ? "Select Shipping Address" : "Select Shipping Site"}
			placeholder={isGuest ? "Select Shipping Address" : "Select Shipping Site"}
			getOptionLabel={(option) =>
				`${option.name ?? option.customerNumber} - ${getAddressString(option)}`
			}
			renderOption={(option) => {
				return (
					<div>
						<Paragraph>{option.name ?? option.customerNumber}</Paragraph>
						<Paragraph small color="secondary">
							{getAddressString(option)}
						</Paragraph>
					</div>
				)
			}}
			filterOptions={(selectOptions, selectState) => {
				if (sites && selectState?.inputValue?.trim()) {
					return sites?.filter((option) =>
						`${option.name ?? option.customerNumber} - ${getAddressString(option)}`
							.toLowerCase()
							.includes(selectState.inputValue.trim())
					)
				}
				return sites || []
			}}
			{...rest}
			value={value}
			disableAutocomplete={false}
			onItemSelect={onChange}
			options={sites ?? []}
			sortingParam="name"
		/>
	)
}
