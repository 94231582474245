import React from "react"
export interface VisualSearchCameraCircleIconProps {
	color?: string
	width?: string
	height?: string
}

export const VisualSearchCameraCircleIcon: React.FC<VisualSearchCameraCircleIconProps> = ({
	color,
	width,
	height,
	...rest
}) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				viewBox="0 0 64 64"
				fill="none"
			>
				<rect x="0.25" y="0.25" width="63.5" height="63.5" rx="31.75" fill="white" />
				<rect
					x="0.25"
					y="0.25"
					width="63.5"
					height="63.5"
					rx="31.75"
					stroke="#87CEFA"
					stroke-width="0.5"
				/>
				<path
					d="M19.7124 14L18.0001 19.1368L12.8633 20.8491V22.4021L18.0001 24.1144L19.7124 29.2512H21.2654L22.9777 24.1144L28.1145 22.4021V20.8491L22.9777 19.1368L21.2654 14H19.7124Z"
					fill="url(#paint0_linear_2179_4389)"
				/>
				<path
					d="M32.7668 30.6294C30.9585 30.6294 29.4927 32.0953 29.4927 33.9035C29.4927 35.7118 30.9585 37.1777 32.7668 37.1777C34.575 37.1777 36.0409 35.7118 36.0409 33.9035C36.0409 32.0953 34.575 30.6294 32.7668 30.6294Z"
					fill="url(#paint1_linear_2179_4389)"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M16.3962 32.2665V37.5727C16.3962 38.8904 16.3962 39.9781 16.4685 40.864C16.5437 41.7842 16.7051 42.6301 17.1099 43.4246C17.7377 44.6567 18.7395 45.6585 19.9716 46.2863C20.7661 46.6911 21.612 46.8525 22.5322 46.9277C23.4181 47 24.5057 47 25.8235 47H39.7101C41.0278 47 42.1154 47 43.0014 46.9277C43.9216 46.8525 44.7674 46.6911 45.562 46.2863C46.7941 45.6585 47.7958 44.6567 48.4236 43.4246C48.8285 42.6301 48.9898 41.7842 49.065 40.864C49.1374 39.9781 49.1374 38.8905 49.1374 37.5727L49.1375 29.0675C49.1385 27.4877 49.1393 26.3128 48.8101 25.3109C48.1605 23.3343 46.6102 21.784 44.6335 21.1344C43.6317 20.8051 42.4567 20.8059 40.8769 20.8069L40.8031 20.807L39.3808 19.4095C38.9117 18.9478 38.4982 18.5408 38.0018 18.2408C37.5657 17.9773 37.0913 17.7832 36.5955 17.6656C36.0312 17.5317 35.451 17.5323 34.7928 17.5329L31.1296 17.5329L31.1298 24.0812L25.4 26.5368L22.9444 32.2665H16.3962ZM26.2185 33.9035C26.2185 30.2871 29.1503 27.3553 32.7668 27.3553C36.3833 27.3553 39.315 30.2871 39.315 33.9035C39.315 37.52 36.3833 40.4518 32.7668 40.4518C29.1503 40.4518 26.2185 37.52 26.2185 33.9035Z"
					fill="url(#paint2_linear_2179_4389)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_2179_4389"
						x1="15.8861"
						y1="16.5"
						x2="48.9049"
						y2="47.2499"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_2179_4389"
						x1="15.8861"
						y1="16.5"
						x2="48.9049"
						y2="47.2499"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_2179_4389"
						x1="15.8861"
						y1="16.5"
						x2="48.9049"
						y2="47.2499"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#0B75E1" />
						<stop offset="1" stop-color="#87CEFA" />
					</linearGradient>
				</defs>
			</svg>
		</span>
	)
}
